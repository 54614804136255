<template>
  <div>
    <div @click="toBlogDetails($event)" class="news-item news-item-sm">
      <router-link :to="`/blog/${blog.slug}`" class="news-img-link">
        <div class="news-item-img">
          <img
            style="object-fit: cover"
            class="resp-img"
            v-lazyload
            :data-url="
              blog
                ? `${blog.headerImage}`
                : require('../assets/images/default.webp')
            "
            alt="blog image"
          />
        </div>
      </router-link>
      <div class="news-item-text">
        <router-link :to="`/blog/${blog.slug}`"
          ><h3>{{ blog.title }}</h3></router-link
        >
        <div class="dates">
          <span class="date">{{ blog.createdAtDate }} /
            <img
              class="w-30 share-icon"
              :src="require('../assets/images/icons/share-icon-green.webp')"/></span>
        </div>
        <div class="news-item-descr">
          <p>
            {{ inner }}...
          </p>
        </div>
        <div class="news-item-bottom">
          <router-link :to="`/blog/${blog.slug}`" class="news-link"
            >Read more...</router-link
          >
          <div class="admin"></div>
        </div>
      </div>
    </div>
    <b-modal
      @shown="setWidth"
      hide-footer
      centered
      scrollable
      :ref="`share-modal${blog.id}`"
      :id="`share-modal${blog.id}`"
      title="Share"
    >
      <ul style="list-style: none; padding: 0px">
        <li
          class="pb-2 pt-2 d-flex justify-content-center"
          style="border-bottom: 1px solid grey"
        >
          <ShareNetwork
            network="facebook"
            :url="`https://vencasa.com/blog/${blog.slug}`"
            :title="blog.title"
            :description="inner"
            :quote="blog.title"
            hashtags="vencasa"
          >
            <i style="color: #548f4d" class="fab fah fa-lg fa-facebook"></i>
            Facebook
          </ShareNetwork>
        </li>
        <li
          class="pb-2 pt-2 d-flex justify-content-center"
          style="border-bottom: 1px solid grey"
        >
          <ShareNetwork
            network="twitter"
            :url="`https://vencasa.com/blog/${blog.slug}`"
            :title="blog.title"
            :description="inner"
            :quote="blog.title"
            hashtags="vencasa"
          >
            <i style="color: #548f4d" class="fab fah fa-lg fa-twitter"></i>
            Twitter
          </ShareNetwork>
        </li>
        <li
          class="pb-2 pt-2 d-flex justify-content-center"
          style="border-bottom: 1px solid grey"
        >
          <ShareNetwork
            network="linkedin"
            :url="`https://vencasa.com/blog/${blog.slug}`"
            :title="blog.title"
            :description="inner"
            :quote="blog.title"
            hashtags="vencasa"
          >
            <i style="color: #548f4d" class="fab fah fa-lg fa-linkedin-in"></i>
            LinkedIn
          </ShareNetwork>
        </li>
        <li
          class="pb-2 pt-2 d-flex justify-content-center"
          style="border-bottom: 1px solid grey"
        >
          <ShareNetwork
            network="email"
            :url="`https://vencasa.com/blog/${blog.slug}`"
            :title="blog.title"
            :description="inner"
            :quote="blog.title"
            hashtags="vencasa"
          >
            <i style="color: #548f4d" class="fas fa-lg fa-envelope"></i> Email
          </ShareNetwork>
        </li>
      </ul>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import Quill from "quill";

export default {
  mounted() {
    let article = document.createElement("article");
    let quill = new Quill(article, { readOny: true });
    quill.setContents(JSON.parse(this.blog.description));
    this.inner = quill.root.innerText.substr(0, 300);
  },
  data() {
    return {
      user: null,
      inner: "",
    };
  },
  methods:{
    setWidth() {
      document.querySelector(
        `#share-modal${this.blog.id}___BV_modal_content_`
      ).style.width = "300px";
    },
    toBlogDetails(event) {
      if (event.target.classList.contains("share-icon"))
        this.$refs[`share-modal${this.blog.id}`].show();
      else this.$router.push(`/blog/${this.blog.slug}`);
    },
  },
  props: ["blog"],
};
</script>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>

div >>> p {
  margin: 0;
  white-space: break-spaces;
}

.w-30 {
  width: 15px;
  height: 15px;
}
</style>